.serviceCard {
  background-color: #ecfeb7;
  transition: all 500ms;
}

.serviceCard article {
  font-size: 18px;
}

.serviceCard:hover {
  box-shadow: 0px 0px 20px 0px #e5e5e5;
  transition: all 300ms;
  user-select: none;
  transform: scale(1.1);
}
.bntCardService {
  margin-top: 20px;
  border-radius: 6px;
  background-color: ;
  color: #235a4b !important;
  transition: all 500ms !important;
  border: 1px solid #235a4b;
}

.bntCardService:hover {
  background-color: #235a4b20;
}

.cardAboutOne {
  padding: 30px 30px 0px;
  background-color: #ede0d4;
  margin-top: 60px;
}

.cardAboutOne article {
  font-size: 17px;
}
.cardAboutTwo article {
  font-size: 17px;
}
.cardAboutTree article {
  font-size: 17px;
}

.cardAboutTwo {
  padding: 30px 30px 184px;
  background-color: #ecf39e;
}

.cardAboutTree {
  margin-top: 70px;
  padding: 30px 30px;
  background-color: #cad2c5;
}

.mapboxgl-control-container {
  visibility: hidden !important;
}

.marker {
  width: 50px;
  height: 50px;
  border-radius: 50%;
  cursor: pointer;
  background-image: red;
}
